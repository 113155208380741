import React from 'react';
import {Box, styled, Typography} from "@mui/material";
import heroImg from '../../assets/images/hero.jpeg';

const Text = styled(Typography)`
  color: #FFF;
  font-weight: 600;
  text-shadow: -3px 3px 0 #00000033;
  word-spacing: 100vw;
  line-height: 1;
  letter-spacing: 1px;
  ${({ theme }) => theme.breakpoints.only('xl')} {
    font-size: 7.2rem;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: 4.2rem;
    line-height: 1.05;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    line-height: 1;
    font-size: 3.1rem;
  }
`;

const Background = styled(Box)<{ component?: React.ElementType }>`
  display: flex;
  align-items: end;
  border-radius: 24px;
  padding: 40px 60px;
  background-image: url(${heroImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 560px;
  
  ${({ theme }) => theme.breakpoints.only('sm')} {
    height: 400px;
    padding: 32px 38px;
  }

  ${({ theme }) => theme.breakpoints.only('xs')} {
    height: 280px;
    padding: 20px;
    margin: 0 14px;
  }
`

const TEXT = 'Sofia Service Solutions'

const Hero = () => {
  return (
    <Background id={'hero'} component={'section'}>
      <Text variant="h1">{TEXT}</Text>
    </Background>
  )
}

export default Hero;