import React from 'react';
import Box from "@mui/material/Box";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {Button, styled, TextareaAutosize, TextField} from "@mui/material";
import Swal from 'sweetalert2';
import {sendFormData} from "../../services/formspree";

const Label = styled('label')`
  font-family: Gilroy, sans-serif;
  color: #FFF;
  margin-bottom: 8px;
`

const HelperText = styled('p')`
  font-family: Gilroy, sans-serif;
  margin: 4px 5px 0 5px;
  color: #d32f2f;
  font-size: 0.75rem;
`

const Textarea = styled(TextareaAutosize)<{ error?: string }>`
  transition: 0.15s;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  border: 1px solid ${({ error }) => (error ? 'red' : '#FFF')};
  border-radius: 12px 12px 0 12px;
  max-width: calc(100% - 22px);
  min-width: calc(100% - 22px);
  min-height: 100px;
  outline: unset;
  padding: 10px;
  &:hover {
    border: 1px solid ${({ error }) => (error ? 'red' : '#8a8a8a')};
  }
  &:focus-visible {
    border: 1px solid ${({ error }) => (error ? 'red' : '#5c5c5c')};
  }
`

const Row = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const validationSchema = Yup.object().shape({
  name: Yup.string().matches(/^.*\S.*$/, 'Name is required').required('Name is required'),
  company: Yup.string().optional(),
  email: Yup.string().matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    'Email should be in the format name@email.com'
  ).required('Email is required'),
  enquiry: Yup.string().matches(/^.*\S[\s\S]*$/, 'Inquiry is required').required('Inquiry is required'),
})

const INITIAL_VALUES = {
  name: '',
  company: '',
  email: '',
  enquiry: '',
}

const Form = () => {

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    resetForm,
    isSubmitting
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await sendFormData('mgvewlpb', values).then((response) => {
        Swal.fire({
          title: "Thank you!",
          text: "Your message has been successfully sent. We will contact you shortly to address your questions.",
          confirmButtonColor: '#45C0B9',
          confirmButtonText: 'Great',
          showCloseButton: true,
        });
        resetForm()
      }).catch((err) => {
        Swal.fire({
          title: "Something Went Wrong",
          text: "There was an error sending your message. Please try again later.",
          confirmButtonColor: '#45C0B9',
          confirmButtonText: 'Ok',
          showCloseButton: true,
        });
        resetForm()
      });
    },
  });

  return (
    <Box width={'100%'} maxWidth={'280px'}>
      <form onSubmit={handleSubmit}>
        <Row mb={2}>
          <Label htmlFor={'name'}>Name*</Label>
          <TextField
            id='name'
            name='name'
            value={values.name}
            onChange={handleChange}
            helperText={!!errors.name && !!touched.name && errors.name}
            error={!!errors.name && !!touched.name}
            size={'small'}
            fullWidth
          />
        </Row>
        <Row mb={2}>
          <Label htmlFor={'company'}>Company</Label>
          <TextField
            id='company'
            name='company'
            value={values.company}
            onChange={handleChange}
            helperText={!!errors.company && !!touched.company && errors.company}
            error={!!errors.company && !!touched.company}
            size={'small'}
            fullWidth
          />
        </Row>
        <Row mb={2}>
          <Label htmlFor={'email'}>Email*</Label>
          <TextField
            id='email'
            name='email'
            value={values.email}
            onChange={handleChange}
            helperText={!!errors.email && !!touched.email && errors.email}
            error={!!errors.email && !!touched.email}
            size={'small'}
            fullWidth
          />
        </Row>
        <Row mb={2}>
          <Label htmlFor={'enquiry'}>Inquiry*</Label>
          <Textarea
            id='enquiry'
            name='enquiry'
            value={values.enquiry}
            onChange={handleChange}
            error={!!errors.enquiry && !!touched.enquiry ? 'error' : ''}
          />
          {!!errors.enquiry && !!touched.enquiry && (
            <HelperText>{errors.enquiry}</HelperText>
          )}
        </Row>
        <Box display='flex' justifyContent='center'>
          <Button
            type='submit'
            variant='white'
            fullWidth
            disabled={isSubmitting}
          >
            Book an Appointment
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Form;