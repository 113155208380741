import React from 'react';
import {Box, Grid} from "@mui/material";
import whyChooseUsImg from '../../assets/images/why-choose-us.jpeg'
import graduationImg from '../../assets/icons/graduation.svg'
import handshakeImg from '../../assets/icons/handshake.svg'
import checkCircleImg from '../../assets/icons/check-circle.svg'
import circuitryImg from '../../assets/icons/circuitry.svg'
import {SquareImage, SubTitle, Title} from "../shared/styled-components";
import ListElement from "../shared/list-element/ListElement";

const reasons = [
  {
    icon: <img src={graduationImg} alt={'Graduation'}/>,
    title: 'Industry Expertise',
    description: 'With years of experience in communications and entertainment, we meet and exceed industry demands, keeping your business competitive and customers satisfied.',
  },
  {
    icon: <img src={handshakeImg} alt={'Handshake'}/>,
    title: 'Tailored Solutions',
    description: 'We create personalized solutions to meet your specific needs and goals efficiently.',
  },
  {
    icon: <img src={checkCircleImg} alt={'Check circle'}/>,
    title: 'Commitment to Quality',
    description: 'Our focus on quality ensures we hire top talent and implement rigorous training programs.',
  },
  {
    icon: <img src={circuitryImg} alt={'Circuitry'}/>,
    title: 'Innovative Technology',
    description: 'We use the latest technology, including advanced CRM systems and AI-driven analytics, to improve service delivery and provide valuable insights.',
  },
];

const WhyChooseUs = () => {
  return (
    <Box id={'why-choose-us'} component={'section'} pb={{xs: 4, sm: 5, md: 7}} pt={{md: 4}}>
      <Grid container spacing={{xs: 3, sm: 4, md: 5}}>
        <Grid item xs={12} md={6} order={{ xs: 2, sm: 2, md: 1 }}>
          <Title variant="h4" gutterBottom>
            Why Choose Us
          </Title>
          <SubTitle variant="body1" paragraph>
            Tailored solutions and innovative technology
          </SubTitle>
          <Grid container spacing={2}>
            {reasons.map((reason, index) => (
              <ListElement
                key={index}
                icon={reason.icon}
                title={reason.title}
                description={reason.description}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} display={'flex'} alignItems={'end'} justifyContent={'center'} order={{ xs: 1, sm: 1, md: 2 }}>
          <SquareImage src={whyChooseUsImg} alt="Team at work" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhyChooseUs;