import React from 'react';
import {Box, styled, Typography} from "@mui/material";
import Form from "./Form";

const Wrapper = styled(Box)<{ component?: React.ElementType }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #45C0B9;
  border-radius: 24px;
  position: relative;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    margin: 0 14px;
  }
`

const Title = styled(Typography)`
  color: #FFF;
  font-weight: 600;
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: 2.4rem;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 2.2rem;
  }
`

const SubTitle = styled(Typography)`
  color: #FFF;
`

const ContactUs = () => {
  return (
    <Wrapper id={'contact-us'} component={'section'} p={2}>
      <Title color={'#FFF !important'} variant={'h4'}>Contact Us</Title>
      <SubTitle>We are ready to help you any time</SubTitle>
      <Box display={'flex'} justifyContent={'center'} pt={2}>
        <Form />
      </Box>
    </Wrapper>
  );
};

export default ContactUs;