import React from 'react';
import {Box, Grid} from "@mui/material";
import ourServiceImg from '../../assets/images/our-services.jpeg'
import phoneImg from '../../assets/icons/phone.svg'
import chatImg from '../../assets/icons/chat.svg'
import desktopImg from '../../assets/icons/desktop.svg'
import earthImg from '../../assets/icons/earth.svg'
import {SquareImage, SubTitle, Title} from "../shared/styled-components";
import ListElement from "../shared/list-element/ListElement";

const services = [
  {
    icon: <img src={phoneImg} alt={'phone'}/>,
    title: 'Phone Support',
    description: 'Our dedicated customer service team provides professional assistance through phone calls, ensuring that every interaction is handled with care and efficiency.'
  },
  {
    icon: <img src={chatImg} alt={'chat'}/>,
    title: 'Chat Support',
    description: 'Receive instant help via chat for quick and effective problem resolution, allowing for immediate assistance and smooth communication.'
  },
  {
    icon: <img src={desktopImg} alt={'desktop'}/>,
    title: 'IT Support',
    description: 'Our robust technical support services ensure seamless business operations by addressing technical issues promptly and efficiently.'
  },
  {
    icon: <img src={earthImg} alt={'earth'}/>,
    title: 'Outsourcing',
    description: 'We provide tailored solutions to enhance operational efficiency and achieve peak performance, helping you focus on your core business activities.'
  }
];

const OurServices = () => {
  return (
    <Box id={'our-services'} component={'section'} pt={{xs: 4, sm: 5, md: 7}} pb={4}>
      <Grid container spacing={{xs: 3, sm: 4, md: 5}}>
        <Grid item xs={12} md={6} display={'flex'} alignItems={'end'}>
          <SquareImage src={ourServiceImg} alt="Team at work" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <Title variant="h4">
              Our Services
            </Title>
          </Box>
          <SubTitle variant="body1" paragraph>
            We offer a wide range of support services
          </SubTitle>
          <Grid container spacing={2}>
            {services.map((service, index) => (
              <ListElement
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OurServices;