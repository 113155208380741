import React from 'react';
import {Box, styled, Typography} from "@mui/material";

const TEXT = '© 2024 Sofia Service Solutions. All rights reserved.'

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  text-align: center;
`

const Footer = () => {
  return (
    <footer id={'footer'}>
      <Wrapper p={2}>
        <Typography color={'#1B1C1E'}>{TEXT}</Typography>
      </Wrapper>
    </footer>
  );
};

export default Footer;