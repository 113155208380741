
type FormValues = {
  [key: string]: string;
};

export async function sendFormData(formKey: string, values: FormValues): Promise<Response> {
  const url = `https://formspree.io/f/${formKey}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      throw new Error(`Form submission failed: ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error('Error submitting form:', error);
    throw error;
  }
}