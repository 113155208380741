import React from 'react';
import {Box, styled, Typography} from "@mui/material";
import quoteImg from '../../assets/icons/quote.svg';

const Wrapper = styled(Box)<{ component?: React.ElementType }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F5F5F5;
  border-radius: 24px;
  padding: 52px 30px;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    margin: 0 14px 24px 14px;
    padding: 24px;
  }
`

const Text = styled(Typography)`
  font-size: 1.25rem;
  font-style: italic;
  text-align: center;
  max-width: 740px;
  color: #000000;
  @media (max-width: 480px) {
    font-size: 1.15rem;
  }
  @media (max-width: 320px) {
    font-size: 1rem;
  }
`

const TEXT = 'We are not just another service provider — we are your strategic partner, committed to supporting your business goals and delivering exceptional results.'

const Quote = () => {
  return (
    <Wrapper id={'quote'} component={'section'} mb={8} mt={2}>
      <Box mb={2}>
        <img src={quoteImg} alt={'Quote'}/>
      </Box>
      <Text>{TEXT}</Text>
    </Wrapper>
  );
};

export default Quote;