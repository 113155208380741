import {styled} from "@mui/material";
import Typography from "@mui/material/Typography";

export const Title = styled(Typography)`
  color: #45C0B9;
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: 2.4rem;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 2.2rem;
  }
`

export const SubTitle = styled(Typography)`
  font-size: 1.3rem;
  line-height: unset;
  margin-bottom: 35px;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: 1.5rem;
  }
`

export const SquareImage = styled('img')`
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
  border-radius: 24px;
  ${({ theme }) => theme.breakpoints.only('sm')} {
    max-width: unset;
    max-height: 320px;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    max-width: unset;
    max-height: 270px;
  }
`