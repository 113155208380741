import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Button, Drawer, IconButton, List as MuiList, ListItem, ListItemButton, ListItemText, styled} from "@mui/material";
import logo from '../../assets/logo.svg';
import hamburgerImg from '../../assets/icons/hamburger.svg';
import crossImg from '../../assets/icons/cross.svg';
import {useState} from "react";

const sections = [
  { id: 'about-us', name: 'About Us' },
  { id: 'our-services', name: 'Our Services' },
]

const Logo = styled('img')`
  height: 100%;
  width: 100%;
  max-width: 130px;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    max-width: 100px;
  }
`

const List = styled(MuiList)`
  height: calc(100dvh - 75px);
  display: flex;
  flex-direction: column;
`

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false)

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu)
  }
  const handleScrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  const handleScrollToMobileSection = (id: string) => {
    handleToggleMenu()
    handleScrollToSection(id)
  }

  return (
    <AppBar position="relative" sx={{zIndex: 1400}}>
      <Container>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', sm: 'flex' },
              fontFamily: 'monospace',
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Logo src={logo} alt={'Logo'}/>
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', sm: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Logo src={logo} alt={'Logo'}/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, justifyContent: 'center', gap: 3 }}>
            {sections.map((section) => (
              <Button
                key={section.id}
                onClick={() => handleScrollToSection(section.id)}
                sx={{ my: 2, display: 'block' }}
                disableRipple
              >
                {section.name}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!openMenu && (
              <Button
                variant={'contained'}
                onClick={() => handleScrollToSection('contact-us')}
              >
                Contact Us
              </Button>
            )}
            <Box sx={{ display: { xs: 'block', sm: 'none' }, ml: 1}}>
              <IconButton onClick={handleToggleMenu}>
                <img
                  src={openMenu ? crossImg : hamburgerImg}
                  alt={openMenu ? 'close' : 'hamburger'}
                />
              </IconButton>
              <Drawer
                anchor={'right'}
                open={openMenu}
                onClose={handleToggleMenu}
              >
                <Box mt={7}>
                  <List>
                    {sections.map((section, index) => (
                      <ListItem key={index} disablePadding style={{borderTop: index === 0 ? '1px solid' : ''}}>
                        <ListItemButton onClick={() => handleScrollToMobileSection(section.id)}>
                          <ListItemText primary={section.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                    <ListItem sx={{marginTop: 'auto'}}>
                      <Button
                        variant={'contained'}
                        style={{textTransform: 'unset'}}
                        onClick={() => handleScrollToMobileSection('contact-us')}
                        fullWidth
                      >
                        Book an Appointment
                      </Button>
                    </ListItem>
                  </List>
                </Box>
              </Drawer>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
