import React, {FC} from 'react';
import {Box, Grid, styled, Typography} from "@mui/material";


const Title = styled(Typography)`
  color: #000000;
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: 1.4rem;
  }
`

const Text = styled(Typography)`
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: 1.2rem;
  }
`

type TypeProps = {
  icon: JSX.Element;
  title: string;
  description: string;
}

const ListElement: FC<TypeProps> = ({icon, title, description}) => {
  return (
    <Grid item xs={12}>
      <Box>
        <Box display={'flex'}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mr={1}>{icon}</Box>
          <Title variant="h6">{title}</Title>
        </Box>
        <Text>{description}</Text>
      </Box>
    </Grid>
  );
};

export default ListElement;