import React from 'react';
import {Box, styled, Typography} from "@mui/material";
import aboutUsImg from '../../assets/images/about-us.png'
import {Title} from "../shared/styled-components";

const Wrapper = styled(Box)<{ component?: React.ElementType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F5F5F5;
  border-radius: 24px;
  padding: 55px 20px;
  gap: 25px;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding: 40px 20px;
    gap: 15px;
  }
`

const Image = styled('img')`
  width: 100%;
  max-width: 780px;
  border-radius: 24px;
  min-height: 200px;
  object-fit: cover;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    min-height: unset;
    height: 345px;
  }
`

const Description = styled(Typography)`
  max-width: 850px;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 200;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    font-size: 1.15rem;
    font-weight: 400;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-weight: 400;
    font-size: 1.4rem;
  }
  b {
    font-weight: 500;
    color: #000000;
  }
`

const AboutUs = () => {
  return (
    <Wrapper id={'about-us'} component={'section'}>
      <Title variant={'h4'}>About Us</Title>
      <Image src={aboutUsImg} alt='About us'/>
      <Description>Sofia Service Solutions is based in Sofia, Bulgaria, and offers unparalleled customer service and operational support thanks to its strategic approach and highly skilled team. Our founder, Robert Davies, has a wealth of experience in customer service and operations management. His leadership drives our company towards continuous improvement and outstanding results.</Description>
    </Wrapper>
  );
};

export default AboutUs;
