import React from 'react';
import './App.css';
import Header from "./components/header/Header";
import {Container as MuiContainer, styled} from "@mui/material";
import Hero from "./components/hero/Hero";
import ExceptionalCustomerService from "./components/exceptional-customer-service/ExceptionalCustomerService";
import AboutUs from "./components/about-us/AboutUs";
import OurServices from "./components/our-services/OurServices";
import WhyChooseUs from "./components/why-choose-us/WhyChooseUs";
import Quote from "./components/quote/Quote";
import Footer from "./components/footer/Footer";
import ContactUs from "./components/contact-us/ContactUs";

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Limiter = styled('div')`
  max-width: 1010px;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    margin: 0 14px;
  }
`

const Container = styled(MuiContainer)<{ component?: React.ElementType }>`
  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding-left: 0;
    padding-right: 0; 
  }
`

function App() {
  return (
    <div className="App">
      <Header/>
      <Container id={'main'} component={'main'} maxWidth={'lg'}>
        <Hero/>
        <ExceptionalCustomerService/>
        <AboutUs/>
        <Wrapper>
          <Limiter>
            <OurServices/>
            <WhyChooseUs/>
          </Limiter>
        </Wrapper>
        <Quote/>
        <ContactUs/>
      </Container>
      <Footer/>
    </div>
  );
}

export default App;
