import React from 'react';
import {Box, styled, Typography} from "@mui/material";

// const Title = styled(Typography)`
//   color: #45C0B9;
//   font-weight: 500;
//   font-size: 3.1rem;
//   @media (max-width: 320px) {
//     font-size: 1.6rem;
//     padding-bottom: 10px;
//   }
//   @media (max-width: 480px) {
//     padding-bottom: 10px;
//   }
//   ${({ theme }) => theme.breakpoints.up('lg')} {
//     font-size: 3.1rem;
//   }
// `

const Title = styled(Typography)`
  color: #45C0B9;
  font-weight: 500;
  font-size: 3.1rem;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    font-size: 1.6rem;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: 2.4rem;
  }
  ${({ theme }) => theme.breakpoints.only('md')} {
    font-size: 2.4rem;
  }
`

const Text = styled(Typography)`
  font-size: 1.3rem;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    font-size: 1.15rem;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    font-size: 1.4rem;
  }
`

const Wrapper = styled(Box)<{ component?: React.ElementType }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 420px;
  gap: 15px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    min-height: 320px;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    margin: 0 14px;
    min-height: 270px;
    padding: 35px 0;
  }
`

const CONTENT = {
  TITLE_1: 'Exceptional customer service.',
  TITLE_2: 'Seamless operations.',
  DESCRIPTION: 'We specialize in providing top-tier customer service representatives and operations staff who are not only highly skilled but also deeply passionate about ensuring your business runs smoothly and your customers are always satisfied.',
}

const ExceptionalCustomerService = () => {
  return (
    <Wrapper id={'exceptional-customer-service'} component={'section'}>
      <Box display={'flex'} flexDirection={'column'} textAlign={'center'} gap={1}>
        <Title variant={'h3'}>{CONTENT.TITLE_1}</Title>
        <Title variant={'h3'}>{CONTENT.TITLE_2}</Title>
      </Box>
      <Text maxWidth={660}>{CONTENT.DESCRIPTION}</Text>
    </Wrapper>
  );
};

export default ExceptionalCustomerService;