import {createTheme, responsiveFontSizes} from "@mui/material";

const createdTheme = createTheme({
  typography: {
    fontFamily: 'Gilroy, sans-serif',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#606164',
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
          boxShadow: 'unset',
          color: '#000'
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: '1300px!important',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          background: '#FFF',
          boxShadow: 'unset',
          '&:hover': {
            backgroundColor: '#e1e1e1',
            boxShadow: 'unset',
          },
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: '12px',
          backgroundColor: '#45C0B9',
          boxShadow: 'unset',
          color: '#fff',
          textTransform: 'capitalize',
          fontSize: '1rem',
          fontWeight: 300,
          '&:hover': {
            backgroundColor: '#6FDAD4',
            boxShadow: 'unset',
          },
          '&:active': {
            backgroundColor: '#39B1AA',
            boxShadow: 'unset',
          },
        },
        text: {
          borderRadius: '12px',
          backgroundColor: 'transparent',
          boxShadow: 'unset',
          color: '#000',
          textTransform: 'capitalize',
          fontSize: '1rem',
          fontWeight: 300,
          '&:hover': {
            color: '#45C0B9',
            backgroundColor: 'transparent',
            boxShadow: 'unset',
          },
          '&:active': {
            color: '#39B1AA',
            backgroundColor: 'transparent',
            boxShadow: 'unset',
          },
        },
      },
      variants: [
        {
          props: { variant: 'white' },
          style: {
            backgroundColor: '#FFF',
            color: '#000',
            borderRadius: '12px',
            padding: '5px 15px',
            textTransform: 'unset',
            '&:hover': {
              backgroundColor: '#e1e1e1',
            },
          },
        },
      ],
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          textAlign: 'right',
          fontWeight: 300,
          color: '#000000',
          fontSize: '1.05rem'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            background: '#FFF',
            '& fieldset': {
              transition: '0.15s',
              borderColor: '#FFF',
              borderWidth: '1px',
            },
            '&:hover fieldset': {
              borderColor: '#8a8a8a',
              borderWidth: '1px',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5c5c5c',
              borderWidth: '1px',
            },
          },
        },
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          height: '100%',
          width: '100%'
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFF',
          color: '#000',
        },
        arrow: {
          color: '#FFF',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 64,
          '@media (min-width:600px)': {
            minHeight: 80,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginLeft: 5,
          marginRight: 5,
        }
      }
    }
  }
});

export const theme =  responsiveFontSizes(createdTheme);

